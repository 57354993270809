// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeMiddleText,
    placeCircle,
    placeAngle,
    placeDash,
    placePlay,
    placeLogo,
    placePause,
    placeFullAngle,
    createSpace
} from '../Utils';
const Boxes = {
    box1: function () {
    //Create the Board
    JXG.Options.board.minimizeReflow = 'none';
    JXG.Options.point.showInfoBox=false;
    JXG.Options.point.highlight=false;
    JXG.Options.image.highlight=false;
    JXG.Options.line.highlight=false;
    JXG.Options.text.highlight=false;
    JXG.Options.text.fixed=true;
    JXG.Options.curve.highlight=false;
    JXG.Options.circle.highlight=false;
    JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
    JXG.Options.layer['curve'] =1;
    JXG.Options.layer['segment'] =3;
    JXG.Options.layer['point'] =7;
    JXG.Options.layer['circle'] =2;
    JXG.Options.layer['angle'] =6;
    var graph =createSpace(-5,9,-6,8);
    graph.suspendUpdate();
    //Make the board responsive
    makeResponsive(graph);
    // Creat titleStyle
    placeTitle(graph, 'Cam Mechanism', '(Converting rotary motion into linear motion)');
    placeLogo(graph);
    var play = placePlay(graph,'left');
    var pause = placePause(graph,'right');
    hoverMe(graph, play, 'Press to Play', 0, -5);
    hoverMe(graph, pause, 'Press to Pause', 0, -5);
    //dragMe(graph, 5, 5);
    //Origin
    var origin=graph.create('point',[0,0], {visible:true, name:'', face:'circle', strokeColor:'black', fillColor:'white', size:22, fixed:true, visible:false});
    ///
    var ogn=graph.create('point',[0,0], {visible:true, name:'', face:'circle', size:2, strokeColor:'black', fillColor:'black'});
    //Radius
    var radius=graph.create('point',[1,0], {visible:false, face:'square', fixed:true});
    //Circle
    var circ = graph.create('circle',[[0,0],[0.41,0]],{visible:true, fillColor:'white', strokeWidth:3, strokeColor:'black'});
    var rota = graph.create('circle',[[0,0],[1.0,0]],{visible:false, fillColor:'white', strokeWidth:3, strokeColor:'black'});
    //Glider
  //  var pt = graph.create('point',[1,0], {visible:false});
    var pt=graph.create('glider',[1.00, 0.0, rota],
    {name:'',size:0, face:'circle',fillColor:'#008CBA',strokeColor:'#f44336', strokeWidth:0, shadow:false});
    //LeftMost
    var tp =graph.create('point',[0,4], {visible:false, fixed:true});
    //RightMost
    var rod =graph.create('segment', [origin, tp], {strokeWidth:4, strokeColor:'red', straightFirst:true, vertices:{visible:false}, visible:false});
    //cam
    //var bb =graph.create('slider',[[-2,-2],[1,-2],[0.4, 0.8, 1.0]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'Ovality', size:8, face:'square', fillColor:'grey', strokeColor:'black', withTicks:false,label:{fontSize:18, cssStyle:'fontFamily:Oswald;'}});
    placeMiddleText(graph, 3, 5.5, 'Displacement Diagram');
    //
    var bb =placeSlider(graph, -2, -2.5, 0.6, 0.85, 0.95, 3, 'Ovality');
    //
  //  var an =graph.create('slider',[[-2,-3],[1,-3],[0.5, 0.75, 1.0]],{baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'Size',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false,label:{fontSize:18, cssStyle:'fontFamily:Oswald;'}});
    var an =placeSlider(graph, -2, -3.5, 0.5, 0.75, 1, 3, 'Size');
    //
    var ang = graph.create('angle', [radius, origin, pt],{label:{visible:false}, visible:false, fillOpacity:1, fillColor:'orange', strokeWidth:0, radius:0.4, orthoType:'sector'});

    var CurveD = graph.create('curve',[function(x){return 0.25+an.Value()/Math.sqrt(1-bb.Value()*bb.Value()*Math.cos(x-ang.Value())*Math.cos(x-ang.Value()))},[0.0, 0], 0, 2*Math.PI],{curveType:'polar', strokeWidth:4, strokeColor:'black', visible:true, fillColor:'red'});
//
    var tpp = graph.create('intersection', [CurveD, rod],{size:7, strokeColor:'black', fillColor:'yellow', visible:false, label:{visible:false}});
//
    var fp = graph.create('point', [function(){return tpp.X();}, function(){return tpp.Y()+0.125}],{size:7,strokeColor:'black', strokeWidth:3, name:''});
//
    var trp = graph.create('point',[0, function(){return tpp.Y()+2.;}],{size:6, name:'', face:'circle', strokeColor:'black', strokeWidth:3, fillColor:'red'});
//
    var level = graph.create('segment',[[0, function(){return tpp.Y()+2;}], [()=>ang.Value(), function(){return tpp.Y()+2;}]],{dash:1, strokeWidth:1, strokeColor:'grey'});
    graph.create('segment',[[()=>ang.Value(), tpp.Y()+2], [()=>ang.Value(), function(){return tpp.Y()+2;}]],{dash:1, strokeWidth:1, strokeColor:'grey'});

    graph.create('segment',[[0, tpp.Y()+2], [2*Math.PI,tpp.Y()+2]],{strokeWidth:1, strokeColor:'grey'});
    graph.create('segment',[[0, tpp.Y()+2], [0,tpp.Y()+4]],{strokeWidth:1, strokeColor:'grey'});

//
    var camr = graph.create('segment', [tpp, [0, function(){return tpp.Y()+2;}]],{strokeWidth:15, strokeColor:'black', vertices:{visible:false}});
//
    var camrC = graph.create('segment', [tpp, [0, function(){return tpp.Y()+2;}]],{strokeWidth:2, strokeColor:'white', dash:0,vertices:{visible:false}});
//Change here
    var tra = graph.create('point', [function(){return ang.Value();}, function(){return 2.25+an.Value()/Math.sqrt(1-bb.Value()*bb.Value()*Math.sin(ang.Value())*Math.sin(ang.Value()));}],{size:3, name:'', face:'circle', strokeColor:'red', fillColor:'red'});
// Change here
    var func=graph.create('functiongraph', [function(x){return 2.25+ an.Value()/Math.sqrt(1-bb.Value()*bb.Value()*Math.sin(x)*Math.sin(x))}, 0, ()=>ang.Value()],{strokeColor:'red', strokeWidth:1, dash:1});

//    var xx =0; var yy=0; var tn=0; var ii=0;
//
      play.on('down', function(){pt.stopAnimation(); pt.startAnimation(-1, 200, 5)});
      //
      pause.on('down', function(){pt.stopAnimation()});
      //
    //  an.on('down', function(){xx=0; yy=0; ii=0});
    //  bb.on('down', function(){xx=0; yy=0; ii=0});
      graph.unsuspendUpdate();

                      }
              }
export default Boxes;
