<template>
  <div>
    <br>
    <h5>Problem Description</h5>
    <p class="big mt-2">
      A cam is a mechanism that converts a rotary motion into a linear oscillatory motion. A cam mechanism typically consists of two moving elements &mdash; a rotating wheel of irregular shape (called the cam) and a lever (called the follower). As the cam rotates, the follower rises and falls thus generating a linear oscillatory motion.
      <br>
    </p>
    <div style="text-align:left">
      <h5> MagicGraph | Area of a Parallelogram</h5>
    </div>
    <p class="big mt-2">
      Use the MagicGraph below to examine the motion of a cam mechanism. Use the sliders provided in the MagicGraph to alter the cam dimensions and see the displacement diagram change. Tap on <i class="redoer ma-1" /> button to start the motion. Tap on <i class="pauser ma-1" /> button to go to pause the motion.
      <br>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <br>
    <h5> Navigation Icons </h5>
    <p class="big mt-2">
      This MagicGraph contains a number of navigation icons/buttons to help you navigate through different parts of the exercise. See below for an explanation of each navigation icon.<br>
      <i class="redoer ma-1" /> is the 'Play' button. Tap on this button to start the animation. <br>
      <i class="pauser ma-1" /> is the 'Pause' button. Tap on this button to pause the animation.<br>
      <i class="logor ma-1" /> is the 'Get a Hint' button. Tap on this button to receive a hint on how to solve this problem. <br>
      <i class="undoer ma-1" /> is the 'Start Over' button. Tap on this button to start over the exercise from the begining.
    </p>
    <h5> Marks & Indications </h5>
    <p class="big mt-2">
      The marks and indications are located on the top-left corner of the MagicGraph, and show your attempt status.<br>
      The right checkmark <i class="righter ma-1" />  indicates that your provided response was correct. <br>
      The crossmark <i class="wrong ma-1" />  indicates that your provided response was incorrect. <br>
      The exclaimation mark<i class="missed ma-1" /> indicates an unattempted (missed) response. <br>
    </p>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'CamMotion',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Motion of a Cam Mechanism';
    this.$store.commit('navigation/changeTitle', title);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Cam Mechanism',
          titleTemplate: '%s | Learn interactively',
          meta: [
                  {vmid: 'description', name: 'description', content: 'Learn interactively about motion of a cam mechanism'}
                ]
        }
   },
}
</script>
